import moment from 'moment';

function getMomentDate(date: Date | string) {
    let dateObject = typeof date === 'string' ? new Date(date) : date;
    let epoch = dateObject.getTime();
    return moment(epoch);
}

export const add = (date: Date | string) => {
    return {
        days: (days: number) => {
            let momentDate = getMomentDate(date);
            let toReturn = momentDate.add(days, 'days');
            return toReturn.toDate();
        },

        months: (month: number) => {
            let momentDate = getMomentDate(date);
            let toReturn = momentDate.add(month, 'months');
            return toReturn.toDate();
        },

        years: (years: number) => {
            let momentDate = getMomentDate(date);
            let toReturn = momentDate.add(years, 'years');
            return toReturn.toDate();
        },
    };
};

export const format = {
    'DD/MM/YYYY': (date: Date | string) => {
        let momentDate = getMomentDate(date);
        return momentDate.format('DD/MM/YYYY');
    },

    'DD/MM/YYYY HH:mm:ss': (date: Date | string) => {
        let momentDate = getMomentDate(date);
        return momentDate.format('DD/MM/YYYY HH:mm:ss');
    },

    'DD-MM-YYYY': (date: Date | string) => {
        let momentDate = getMomentDate(date);
        return momentDate.format('DD-MM-YYYY');
    },

    'YYYY-MM-DD': (date: Date | string) => {
        let momentDate = getMomentDate(date);
        return momentDate.format('YYYY-MM-DD');
    },
};

export const difference = function (date1: Date | string, date2: Date | string) {
    let momentDate1 = getMomentDate(date1);
    let momentDate2 = getMomentDate(date2);
    return {
        days: () => {
            return momentDate1.diff(momentDate2, 'days');
        },

        months: () => {
            return momentDate1.diff(momentDate2, 'months');
        },

        years: () => {
            return momentDate1.diff(momentDate2, 'years');
        },
    };
};
