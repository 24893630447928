import axios, { AxiosInstance } from 'axios';
import { backend } from './config';

class Api {
    private static axiosInstance: AxiosInstance;

    static init() {
        this.axiosInstance = axios.create({
            baseURL: backend.uri,
        });
    }

    static async get<ResponseType>(url: string) {
        return Api.axiosInstance.get<ResponseType>(url);
    }

    static async post<ResponseType, DataType>(url: string, data?: DataType) {
        return Api.axiosInstance.post<ResponseType>(url, data);
    }
}

export default Api;
