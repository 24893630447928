import * as React from 'react';
import { IStoreDetails } from '../../types/store';
import { useState } from 'react';
import { Stack, NumberInput, NumberInputField, Button } from '@chakra-ui/react';
import { cashOrChequeConfirmation } from '../../service/api/cashOrCheque.api';

import { reloadIn3Sec } from '../../lib/Reload';
import { useToast } from '@chakra-ui/react';
interface ICashProps {
    storeDetails: IStoreDetails;
    salesRepId?: string;
}

const Cash: React.FC<ICashProps> = ({ storeDetails, salesRepId }) => {
    const toast = useToast();
    const [input, setInput] = useState({
        amount: 0,
    });

    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [apiCall, setApiCall] = useState({
        made: false,
        paymentSuccess: false,
    });
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });

        if (input.amount) setButtonDisabled(false);
    };

    const callCashOrChequeConfirmation = () => {
        // Api call made
        setApiCall({
            ...apiCall,
            ['made']: true,
        });

        cashOrChequeConfirmation({
            amount: input.amount,
            salesRepId: salesRepId,
            storePhone: storeDetails.storePhone,
            storeId: storeDetails.storeId,
        })
            .then(async (success) => {
                setApiCall({
                    ...apiCall,
                    ['paymentSuccess']: success,
                });
                toast({
                    title: 'Success',
                    description: 'Your request has successfully been sent to the salesman.',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
                await reloadIn3Sec();
            })
            .catch(async (e) => {
                toast({
                    title: 'Failed',
                    description: 'Something Went Wrong !!',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });

                await reloadIn3Sec();
                console.log(e);
            });
    };

    return (
        <div>
            <Stack>
                <NumberInput>
                    <NumberInputField
                        placeholder='Amount'
                        value={input.amount}
                        name={'amount'}
                        onChange={handleChange}
                    />
                </NumberInput>

                <Button
                    colorScheme='teal'
                    variant='solid'
                    disabled={buttonDisabled}
                    onClick={callCashOrChequeConfirmation}
                >
                    Pay Cash
                </Button>
            </Stack>
        </div>
    );
};

export default Cash;
