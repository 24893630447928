import Api from '../../lib/Api';
import { IServerResponse } from '../../types/serverResponse';

export async function getPaymentLink(
    storeId: string,
    amount: number,
    salesRepId?: string,
    origin?: unknown
) {
    let url = `/store/paymentLink?storeId=${storeId}&amount=${amount}`;
    if (salesRepId) url += `&salesRepId=${salesRepId}`;
    if (origin) url += `&origin=${origin as string}`;
    const response = await Api.get<IServerResponse<string>>(url);
    let data = response.data;

    if (data.error) throw new Error(data.error);
    return data.data;
}
