import * as React from 'react';
import { ICredit } from '../types/credit';
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react';

interface ICreditOverviewProps {
    credit: ICredit;
}

const CreditOverview: React.FC<ICreditOverviewProps> = ({ credit }) => {
    return (
        <TableContainer borderWidth={'thin'} borderColor={'black'}>
            <Table variant='simple' size={'sm'}>
                <TableCaption>Credit Overview</TableCaption>
                <Tbody>
                    <Tr>
                        <Td>Total Outstanding</Td>
                        <Td isNumeric>{credit.used || 0}</Td>
                    </Tr>
                    <Tr>
                        <Td color={'red'} fontWeight={'bold'}>
                            <h1>Delayed Outstanding</h1>
                        </Td>
                        <Td color={'red'} fontWeight={'bold'} isNumeric>
                            {credit.overdue || 0}
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>Available Limit</Td>
                        <Td isNumeric>
                            {credit.maxAmount - (credit.used || 0) - (credit.onHold || 0)}
                        </Td>
                    </Tr>
                </Tbody>
            </Table>
        </TableContainer>
    );
};
export default CreditOverview;
