import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import { useState, useEffect } from 'react';

import { Box } from '@chakra-ui/react';
import { Flex, Spacer } from '@chakra-ui/react';

import { IStoreDetails } from '../types/store';
import { IPendingOrders } from '../types/pendingOrders';
import { getStoreDetails } from '../service/api/getStoreDetails.api';
import Loader from '../components/Loader';
import CreditOverview from '../components/Credit-Overview';
import StoreDetails from '../components/Store-Details-card';
import OrderDetailsList from '../components/Orders-Details-List';
import PaymentMethods from '../components/Payment-Methods';

export interface IWebPageApiResponse {
    storeDetails: IStoreDetails;
    orders: IPendingOrders[];
}

interface IDetailsProps extends RouteComponentProps {
    storeId?: string;
    salesRepId?: string;
}

const Details: React.FC<IDetailsProps> = ({ storeId, salesRepId }) => {
    const [webPageApiResponse, setWebPageApiResponse] = useState<IWebPageApiResponse | null>(null);

    useEffect(() => {
        getStoreDetails(storeId, salesRepId).then((data) => {
            setWebPageApiResponse(data);
        });
    }, [storeId]);

    if (!webPageApiResponse) return <Loader />;

    return (
        <Flex px={10} py={3} flexDirection={'column'}>
            <Box flex='1' flexDirection={'row'} backgroundColor={'#6565F7'} paddingX={'25'}>
                <StoreDetails storeDetails={webPageApiResponse?.storeDetails} />
            </Box>
            <Box
                flex='1'
                flexDirection={'column'}
                alignContent={'center'}
                justifyContent={'center'}
                paddingX={'25'}
                marginY={'5'}
            >
                <CreditOverview credit={webPageApiResponse?.storeDetails.credit} />
            </Box>

            <Box
                flex='1'
                flexDirection={'column'}
                alignContent={'center'}
                justifyContent={'center'}
                paddingX={'25'}
                marginY={'5'}
            >
                <OrderDetailsList orders={webPageApiResponse?.orders} />
            </Box>
            {webPageApiResponse.storeDetails.credit.used ? (
                <Box
                    flex='1'
                    flexDirection={'column'}
                    alignContent={'center'}
                    justifyContent={'center'}
                    paddingX={'25'}
                    marginY={'5'}
                >
                    <PaymentMethods
                        storeDetails={webPageApiResponse.storeDetails}
                        salesRepId={salesRepId}
                    />
                </Box>
            ) : null}
        </Flex>
    );
};

export default Details;
