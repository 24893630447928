import { Box, Flex, Spacer } from '@chakra-ui/react';
import * as React from 'react';
import { IStoreDetails } from '../types/store';

interface IStoreDetailsProp {
    storeDetails: IStoreDetails;
}

const StoreDetails: React.FC<IStoreDetailsProp> = ({ storeDetails }) => {
    return (
        <div>
            <Flex alignContent={'center'} color={'white'} justifyContent={'center'}>
                <Box fontSize={20} fontWeight={'bold'}>
                    {storeDetails.distributorName ? `${storeDetails.distributorName} | ` : ''}{' '}
                    Creditail
                </Box>
            </Flex>
            <Flex color={'white'} py={2}>
                <Box>Name</Box>
                <Spacer />
                <Box>{storeDetails.storeName}</Box>
            </Flex>
            <Flex color={'white'} py={2}>
                <Box>Contact No</Box>
                <Spacer />
                <Box>{storeDetails.storePhone}</Box>
            </Flex>
        </div>
    );
};

export default StoreDetails;
