import Api from '../../lib/Api';
import { IServerResponse } from '../../types/serverResponse';
import { IWebPageApiResponse } from '../../screens/Details';
export async function getStoreDetails(storeId?: string, salesRepId?: string) {
    let url = `/store/details/${storeId}`;
    if (salesRepId) url += `/${salesRepId}`;
    const response = await Api.get<IServerResponse<IWebPageApiResponse>>(url);
    let data = response.data;

    if (data.error) throw new Error(data.error);
    return data.data;
}
