import { Box, Container, Stack, Text, useColorModeValue } from '@chakra-ui/react';

const Logo = (props: any) => {
    return (
        <svg height={32} viewBox='0 0 50 28' {...props}>
            <use href='/public/Creditail-logo.png' />
        </svg>
    );
};

export default function SmallWithLogoLeft() {
    return (
        <Box
            bg={useColorModeValue('gray.50', 'gray.900')}
            color={useColorModeValue('gray.700', 'gray.200')}
        >
            <Container
                as={Stack}
                maxW={'6xl'}
                pt={4}
                direction={{ base: 'column', md: 'row' }}
                spacing={4}
                justify={{ base: 'center', md: 'space-between' }}
                // align={{ base: 'center', md: 'center' }}
            >
                <Logo />
                <Text fontSize={13}>© 2022 Creditail. All rights reserved</Text>
                <Stack direction={'row'} spacing={6} ml={40}></Stack>
            </Container>
        </Box>
    );
}
