import * as React from 'react';
import { useState } from 'react';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import { IStoreDetails } from '../../types/store';
import { Stack, NumberInput, NumberInputField, Button, Link } from '@chakra-ui/react';
import { getPaymentLink } from '../../service/api/getPaymentLink.api';
import { useToast } from '@chakra-ui/react';
import Loader from '../Loader';

interface IUpiNetBankingProps {
    storeDetails: IStoreDetails;
    salesRepId?: string;
}

const UpiNetBanking: React.FC<IUpiNetBankingProps> = ({ storeDetails, salesRepId }) => {
    const location = useLocation();
    const queryParams = queryString.parse(location.search);

    const toast = useToast();
    const [input, setInput] = useState({
        amount: 0,
    });

    const [buttonDisabled, setButtonDisabled] = useState(true);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });

        if (input.amount) setButtonDisabled(false);
    };

    const [apiCall, setApiCall] = useState({
        made: false,
        paymentLink: '',
        error: null,
    });
    const callGetPaymentLink = () => {
        // Api call made
        setApiCall({
            ...apiCall,
            ['made']: true,
        });

        getPaymentLink(
            storeDetails.storeId,
            input.amount,
            salesRepId,
            (queryParams && queryParams.origin) || ''
        )
            .then(async (link) => {
                setApiCall({
                    made: true,
                    paymentLink: link,
                    error: null,
                });
                toast({
                    title: 'Success',
                    description: 'Your UPI link is generated',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
            })
            .catch(async (e) => {
                toast({
                    title: 'Failed',
                    description: 'Something Went Wrong !!',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
                setApiCall({
                    made: false,
                    error: e,
                    paymentLink: '',
                });
                console.log(e);
            });
    };

    return (
        <div>
            <Stack>
                {!apiCall.made && (
                    <Stack>
                        <NumberInput>
                            <NumberInputField
                                placeholder='Amount'
                                value={input.amount}
                                name={'amount'}
                                onChange={handleChange}
                            />
                        </NumberInput>
                        <Button
                            colorScheme='teal'
                            variant='solid'
                            disabled={buttonDisabled}
                            onClick={callGetPaymentLink}
                        >
                            Get Payment Link
                        </Button>
                    </Stack>
                )}

                {apiCall.made && !apiCall.paymentLink && <Loader />}
                {apiCall.made && apiCall.error && (
                    <text color='red'>
                        Error in creating payment link. If this issue persists, please contact
                        creditail team
                    </text>
                )}
                {apiCall.made && !apiCall.error && apiCall.paymentLink && (
                    <Link href={apiCall.paymentLink} color='teal.500'>
                        Pay ₹{input.amount}
                    </Link>
                )}
            </Stack>
        </div>
    );
};

export default UpiNetBanking;
