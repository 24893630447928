import Api from '../../lib/Api';
import { IServerResponse } from '../../types/serverResponse';
import { ICashOrChequeConfirmation } from '../../types/cashOrChequeConfirmation';

export async function cashOrChequeConfirmation(payload: ICashOrChequeConfirmation) {
    let url = `whatsapp/cashOrCheque/confirm`;
    const response = await Api.post<IServerResponse<boolean>, ICashOrChequeConfirmation>(
        url,
        payload
    );
    let data = response.data;

    if (data.error) throw new Error(data.error);
    return data.data;
}

interface ICashOrChequePickup {
    storeId: string;
}

export async function cashOrChequePickup(payload: ICashOrChequePickup) {
    let url = `whatsapp/cashOrCheque/pickup`;
    const response = await Api.post<IServerResponse<boolean>, ICashOrChequePickup>(url, payload);
    let data = response.data;

    if (data.error) throw new Error(data.error);
    return data.data;
}
