import * as React from 'react';

import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
} from '@chakra-ui/react';
import { IStoreDetails } from '../../types/store';
import Cash from './cash';
import Cheque from './cheque';
import UpiNetBanking from './upiNetBanking';
import CashOrChequePickup from './cash-Cheque-Pickup';

interface IPaymentMethodProps {
    storeDetails: IStoreDetails;
    salesRepId?: string;
}

const PaymentMethods: React.FC<IPaymentMethodProps> = ({ storeDetails, salesRepId }) => {
    const CashAndChequePayment = (): JSX.Element => {
        if (salesRepId) {
            return (
                <div>
                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box flex='1' textAlign='left'>
                                    Cash
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Cash storeDetails={storeDetails} salesRepId={salesRepId} />
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box flex='1' textAlign='left'>
                                    Cheque
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Cheque storeDetails={storeDetails} salesRepId={salesRepId} />
                        </AccordionPanel>
                    </AccordionItem>
                </div>
            );
        } else {
            return (
                <div>
                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box flex='1' textAlign='left'>
                                    Request Cash/Cheque Pickup
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <CashOrChequePickup storeDetails={storeDetails} />
                        </AccordionPanel>
                    </AccordionItem>
                </div>
            );
        }
    };

    return (
        <Accordion allowToggle>
            <AccordionItem>
                <h2>
                    <AccordionButton>
                        <Box flex='1' textAlign='left'>
                            UPI / NetBanking
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    <UpiNetBanking storeDetails={storeDetails} salesRepId={salesRepId} />
                </AccordionPanel>
            </AccordionItem>

            {CashAndChequePayment()}
        </Accordion>
    );
};

export default PaymentMethods;
