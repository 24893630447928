import * as React from 'react';
import * as dateUtils from '../lib/date-utils';
import { IPendingOrders } from '../types/pendingOrders';
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react';

interface IOrderDetailsListProp {
    orders: IPendingOrders[];
}

const OrderDetailsList: React.FC<IOrderDetailsListProp> = ({ orders }) => {
    const buildTableRow = () => {
        return orders.map((order) => {
            let dueDate = new Date(order.dueDate);
            let [color, fontWeight] = dueDate < new Date() ? ['red', 'bold'] : ['black', 'normal'];
            return (
                <Tr key={order.orderId} color={color} fontWeight={fontWeight}>
                    <Td>{order.invoiceNo}</Td>
                    <Td>{dateUtils.format['DD/MM/YYYY'](order.deliveredAt)}</Td>
                    <Td isNumeric>{order.total}</Td>
                    <Td isNumeric>{order.pendingAmount}</Td>
                    <Td>{dateUtils.format['DD/MM/YYYY'](order.dueDate)}</Td>
                </Tr>
            );
        });
    };

    return (
        <TableContainer borderWidth={'thin'} borderColor={'black'}>
            <Table variant='simple' size={'sm'}>
                <TableCaption>Order Details</TableCaption>
                <Thead>
                    <Tr>
                        <Th>Invoice No</Th>
                        <Th>Delivery Date</Th>
                        <Th isNumeric>Total Amount</Th>
                        <Th isNumeric>Pending Amount</Th>
                        <Th>Due On</Th>
                    </Tr>
                </Thead>
                <Tbody>{buildTableRow()}</Tbody>
            </Table>
        </TableContainer>
    );
};

export default OrderDetailsList;
