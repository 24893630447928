import { CircularProgress } from '@chakra-ui/react';
import { backend } from '../lib/config';
import React from 'react';

const Loader: React.FC = () => {
    console.log(`${backend.uri}`);
    return <CircularProgress isIndeterminate color='green.300' />;
};

export default Loader;
