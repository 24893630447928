import { Router } from '@reach/router';
import { ChakraProvider } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';

import Details from './screens/Details';
import SmallWithLogoLeft from './layout/footer';

function App() {
    return (
        <ChakraProvider>
            <Router>
                <Details path='/:storeId/:salesRepId' />
                <Details path='/:storeId/' />
            </Router>
            <SmallWithLogoLeft />
        </ChakraProvider>
    );
}

export default App;
