import * as React from 'react';
import { useState } from 'react';
import { IStoreDetails } from '../../types/store';
import { cashOrChequePickup } from '../../service/api/cashOrCheque.api';
import { reloadIn3Sec } from '../../lib/Reload';
import { useToast } from '@chakra-ui/react';
import { Stack, Button } from '@chakra-ui/react';

interface ICashOrChequePickupProps {
    storeDetails: IStoreDetails;
}
const CashOrChequePickup: React.FC<ICashOrChequePickupProps> = ({ storeDetails }) => {
    const toast = useToast();
    const [apiCall, setApiCall] = useState({
        made: false,
        pickupSuccess: false,
    });
    const callCashOrChequePickup = () => {
        // Api call made
        setApiCall({
            ...apiCall,
            ['made']: true,
        });

        cashOrChequePickup({
            storeId: storeDetails.storeId,
        })
            .then(async (success) => {
                setApiCall({
                    ...apiCall,
                    ['pickupSuccess']: success,
                });
                toast({
                    title: 'Success',
                    description: 'Your request has successfully been sent to the salesman.',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
                await reloadIn3Sec();
            })
            .catch(async (e) => {
                toast({
                    title: 'Failed',
                    description: 'Something Went Wrong !!',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });

                await reloadIn3Sec();
                console.log(e);
            });
    };

    return (
        <div>
            <Stack>
                <Button colorScheme='teal' variant='solid' onClick={callCashOrChequePickup}>
                    Request Pickup
                </Button>
            </Stack>
        </div>
    );
};

export default CashOrChequePickup;
